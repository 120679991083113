import { useTheme } from '../../../libs/hooks/useTheme';
import type { Theme } from '@emotion/react';

export type TypographyColor =
  | 'primary'
  | 'secondary'
  | 'white'
  | 'default'
  | 'textDisabled'
  | 'error';

export const useTypographyColor = (color: TypographyColor) => {
  const {
    colorWhite,
    colorPrimary,
    colorInfo,
    colorText,
    colorTextDisabled,
    colorError,
  } = useTheme();
  return color === 'primary'
    ? colorPrimary
    : color === 'secondary'
    ? colorInfo
    : color === 'white'
    ? colorWhite
    : color === 'textDisabled'
    ? colorTextDisabled
    : color === 'error'
    ? colorError
    : colorText;
};

export const getTypographyColor = (theme: Theme, color: TypographyColor) => {
  return color === 'primary'
    ? theme.token.colorPrimary
    : color === 'secondary'
    ? theme.token.colorInfo
    : color === 'white'
    ? theme.token.colorWhite
    : color === 'textDisabled'
    ? theme.token.colorTextDisabled
    : color === 'error'
    ? theme.token.colorError
    : theme.token.colorText;
};
