import type { FC, ReactNode } from 'react';
import React from 'react';
import { Typography } from 'antd';
import type { TitleProps } from 'antd/es/typography/Title';
import type { Property } from 'csstype';
import type { FontSizeType } from './useFontSize';
import { getFontSize } from './useFontSize';
import type { TypographyColor } from './useTypographyColor';
import { getTypographyColor } from './useTypographyColor';
import type { FontWeightType } from './useFontWeight';
import styled from '@emotion/styled';
import { media } from '@candypig-monorepo/types';
import { getSize } from '../layout/useSize';

type HeadingProps = Omit<TitleProps, 'type'> & {
  color?: TypographyColor;
  align?: Property.TextAlign;
  margin?: Property.Margin;
  lineHeight?: Property.LineHeight;
  size?: FontSizeType;
  weight?: FontWeightType;
  children: ReactNode;
  sm?: {
    size?: FontSizeType;
    align?: Property.TextAlign;
  };
  md?: {
    size?: FontSizeType;
    align?: Property.TextAlign;
  };
  lg?: {
    size?: FontSizeType;
    align?: Property.TextAlign;
  };
};

const defaultHeadingConfig = [
  {
    level: 1,
    weight: 900,
    fontSize: 40,
    fontSizeSm: 48,
    fontSizeLg: 56,
  },
  {
    level: 2,
    weight: 900,
    fontSize: 40,
    fontSizeSm: 40,
    fontSizeLg: 48,
  },
  {
    level: 3,
    weight: 600,
    fontSize: 28,
    fontSizeSm: 32,
    fontSizeLg: 40,
  },
  {
    level: 4,
    weight: 900,
    fontSize: 24,
    fontSizeSm: 24,
    fontSizeLg: 32,
  },
  {
    level: 5,
    weight: 600,
    fontSize: 20,
    fontSizeSm: 22,
    fontSizeLg: 24,
  },
];

const StyledHeading = styled(Typography.Title, {
  shouldForwardProp: (propName) => !['margin', 'lineHeight'].includes(propName),
})<HeadingProps>(
  ({
    weight,
    lineHeight,
    size,
    color = 'default',
    margin,
    align,
    theme,
    sm,
    md,
    lg,
  }) => ({
    ['&.ant-typography']: {
      fontFamily: '"NunitoVariable", sans-serif',
      marginTop: 0,
      marginBottom: 0,
      margin: typeof margin !== 'undefined' && margin,
      lineHeight: typeof lineHeight !== 'undefined' && lineHeight,
      fontWeight: 500,
      fontVariationSettings: `"wght" 500`,
      fontSize: size && getFontSize(theme, size),
      color: color && getTypographyColor(theme, color),
      textAlign: align,
      ['&+h2.ant-typography, &+h3.ant-typography,&+h4.ant-typography, &+h5.ant-typography']:
        {
          marginTop: getSize(theme, 'xxs'),
        },
    },
    ...Object.fromEntries(
      defaultHeadingConfig.map((heading) => [
        `h${heading.level}&.ant-typography`,
        {
          fontWeight: weight ?? heading.weight,
          fontVariationSettings: `"wght" ${weight ?? heading.weight}`,
          fontSize: getFontSize(theme, size || heading.fontSize),
          [media.sm]: {
            fontSize: getFontSize(
              theme,
              sm?.size || size || heading.fontSizeSm
            ),
            textAlign: sm?.align,
          },
          [media.md]: {
            fontSize: getFontSize(
              theme,
              md?.size || sm?.size || size || heading.fontSizeSm
            ),
            textAlign: md?.align,
          },
          [media.lg]: {
            fontSize: getFontSize(
              theme,
              lg?.size || md?.size || sm?.size || size || heading.fontSizeLg
            ),
            textAlign: lg?.align,
          },
        },
      ])
    ),
  })
);

const Heading: FC<HeadingProps> = ({ level, children, ...props }) => {
  return (
    <StyledHeading
      {...props}
      level={level} //@TODO: make antd render h6 ;/
    >
      {children}
    </StyledHeading>
  );
};
export default Heading;
