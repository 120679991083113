import { useTheme } from '../../../libs/hooks/useTheme';
import type { TypographyColor } from './useTypographyColor';
import type { Theme } from '@emotion/react';

export type ThemeColor = TypographyColor | string;

export const useColor = (color: ThemeColor) => {
  const {
    colorWhite,
    colorPrimary,
    colorInfo,
    colorText,
    colorTextDisabled,
    colorError,
  } = useTheme();

  return color === 'primary'
    ? colorPrimary
    : color === 'secondary'
    ? colorInfo
    : color === 'white'
    ? colorWhite
    : color === 'textDisabled'
    ? colorTextDisabled
    : color === 'error'
    ? colorError
    : color === 'default' || typeof color === 'undefined'
    ? colorText
    : color;
};

export const getColor = (theme: Theme, color: ThemeColor) => {
  return color === 'primary'
    ? theme.token.colorPrimary
    : color === 'secondary'
    ? theme.token.colorInfo
    : color === 'white'
    ? theme.token.colorWhite
    : color === 'textDisabled'
    ? theme.token.colorTextDisabled
    : color === 'error'
    ? theme.token.colorError
    : color === 'default' || typeof color === 'undefined'
    ? theme.token.colorText
    : color;
};
