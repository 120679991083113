import type { ReactNode } from 'react';
import React from 'react';
import type { ColProps } from 'antd';
import { Col as AntdCol } from 'antd';
import type { Property } from 'csstype';

type ColType = ColProps & {
  margin?: Property.Margin;
  padding?: Property.Padding;
  textAlign?: Property.TextAlign;
  children: ReactNode;
};

const Col = ({ children, margin, padding, textAlign, ...props }: ColType) => {
  return (
    <AntdCol
      {...props}
      style={{
        ...props.style,
        ...(textAlign && { textAlign }),
        ...(typeof margin !== 'undefined' && { margin }),
        ...(typeof padding !== 'undefined' && { padding }),
      }}
    >
      {children}
    </AntdCol>
  );
};
export default Col;
