export type FontWeightType = 'normal' | 'bold' | 'bolder' | number;

export const useFontWeight = (weight: FontWeightType = 'normal') => {
  return weight === 'normal'
    ? 400
    : weight === 'bold'
    ? 600
    : weight === 'bolder'
    ? 900
    : weight;
};

export const getFontWeight = (weight: FontWeightType = 'normal') => {
  return weight === 'normal'
    ? 400
    : weight === 'bold'
    ? 600
    : weight === 'bolder'
    ? 900
    : weight;
};
