import type { ReactNode } from 'react';
import React from 'react';
import type { TextProps as AntdTextProps } from 'antd/es/typography/Text';
import type { Property } from 'csstype';

import type { FontSizeType } from './useFontSize';
import { getFontSize } from './useFontSize';
import styled from '@emotion/styled';
import { media } from '@candypig-monorepo/types';
import type { ThemeColor } from './useColor';
import { getColor } from './useColor';

type TextProps = AntdTextProps & {
  children: ReactNode;
  bold?: boolean;
  color?: ThemeColor;
  size?: FontSizeType;
  align?: Property.TextAlign;
  wordBreak?: boolean;
  margin?: Property.Margin;
  lineHeight?: Property.LineHeight;
  weight?: Property.FontWeight;
  sm?: {
    align?: Property.TextAlign;
    size?: FontSizeType;
  };
  md?: {
    align?: Property.TextAlign;
    size?: FontSizeType;
  };
  lg?: {
    align?: Property.TextAlign;
    size?: FontSizeType;
  };
};

const StyledText = styled.p<TextProps>(
  ({
    theme,
    size,
    color,
    margin,
    weight,
    align,
    lineHeight,
    sm,
    lg,
    md,
    wordBreak,
  }) => ({
    fontFamily: "'MontserratVariable', sans-serif",
    gap: theme.token.sizeXXS,
    marginBottom: '0 !important',
    textAlign: align,
    wordWrap: 'break-word',
    fontWeight: weight ?? 600,
    wordBreak: wordBreak ? 'break-all' : null,
    fontVariationSettings: `"wght" ${weight ?? 600}`,
    lineHeight: typeof lineHeight !== 'undefined' && lineHeight,
    margin: typeof margin !== 'undefined' && margin,
    fontSize: getFontSize(theme, size || 'sm'),
    color: getColor(theme, color || 'default'),
    ['span']: {
      margin: '0 2px 0 0',
    },
    [media.sm]: {
      fontSize: getFontSize(theme, sm?.size || size || 'sm'),
      textAlign: sm?.align,
    },
    [media.md]: {
      fontSize: getFontSize(theme, md?.size || sm?.size || size || 'md'),
      textAlign: md?.align,
    },
    [media.lg]: {
      fontSize: getFontSize(
        theme,
        lg?.size || md?.size || sm?.size || size || 'md'
      ),
      textAlign: lg?.align,
    },
  })
);

// TODO: consider merging with Paragraph + change to styled
const Text = ({ children, ...props }: TextProps) => {
  return <StyledText {...props}>{children}</StyledText>;
};

export default Text;
