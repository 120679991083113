import type { SpaceProps as AntdSpaceProps } from 'antd';
import { Space as AntdSpace } from 'antd';
import type { FC, ReactNode } from 'react';
import type { Property } from 'csstype';
import type { SizeType } from './useSize';
import { useSize } from './useSize';
import styled from '@emotion/styled';
import { media } from '@candypig-monorepo/types';

type SpaceProps = Omit<AntdSpaceProps, 'size' | 'align'> & {
  size?: SizeType | [SizeType, SizeType];
  align?: Property.AlignItems;
  justify?: Property.JustifyContent;
  sm?: {
    justify?: Property.JustifyContent;
    align?: Property.AlignItems;
  };
  md?: {
    justify?: Property.JustifyContent;
    align?: Property.AlignItems;
  };
  lg?: {
    justify?: Property.JustifyContent;
    align?: Property.AlignItems;
  };
  full?: boolean;
  fullItem?: boolean;
  children: ReactNode;
};

const StyledSpace = styled(AntdSpace, {
  shouldForwardProp: (propName) =>
    !['full', 'fullItem', 'justify', 'align', 'md', 'lg'].includes(propName),
})<SpaceProps>((props) => ({
  ...(props.full && { width: '100%' }),
  ...(props.fullItem && { ['> .ant-space-item']: { width: '100%' } }),
  ...(props.justify && { justifyContent: props.justify }),
  ...(props.align && { alignItems: props.align }),
  [media.sm]: {
    ...(props.sm?.justify && { justifyContent: props.sm?.justify }),
    ...(props.sm?.align && { alignItems: props.sm?.align }),
  },
  [media.md]: {
    ...(props.md?.justify && { justifyContent: props.md?.justify }),
    ...(props.md?.align && { alignItems: props.md?.align }),
  },
  [media.lg]: {
    ...(props.lg?.justify && { justifyContent: props.lg?.justify }),
    ...(props.lg?.align && { alignItems: props.lg?.align }),
  },
}));

const Space: FC<SpaceProps> = ({ children, size, align, ...props }) => {
  const sizeNum = useSize(size ?? 'xs');

  return (
    <StyledSpace
      align={align as AntdSpaceProps['align']} // TODO: fix this antd type hack after antd upgrade
      size={sizeNum}
      {...props}
    >
      {children}
    </StyledSpace>
  );
};
export default Space;
