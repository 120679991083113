import type { ReactNode } from 'react';
import React from 'react';
import type { RowProps } from 'antd';
import { Row as AntdRow } from 'antd';
import type { Property } from 'csstype';
import type { SizeType } from './useSize';
import { useSize } from './useSize';

type RowType = RowProps & {
  margin?: Property.Margin | number;
  padding?: Property.Padding | number;
  gutterSize?: SizeType | [SizeType, SizeType];
  children: ReactNode;
};

const Row = ({ children, margin, padding, gutterSize, ...props }: RowType) => {
  let sizeNum = useSize(gutterSize);

  if (typeof sizeNum === 'number') {
    sizeNum = [sizeNum, sizeNum];
  }

  return (
    <AntdRow
      gutter={props.gutter ?? sizeNum}
      style={{
        ...props.style,
        ...(typeof margin !== 'undefined' && { margin }),
        ...(typeof padding !== 'undefined' && { padding }),
      }}
      {...props}
    >
      {children}
    </AntdRow>
  );
};
export default Row;
