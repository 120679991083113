import { useTheme } from '../../../libs/hooks/useTheme';
import type { SpaceProps as AntdSpaceProps } from 'antd/es/space';
import type { Theme } from '@emotion/react';
import type { AliasToken } from 'antd/es/theme/interface';

export type SizeType =
  | AntdSpaceProps['size']
  | 'xxs'
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | 'xxl'
  | number;

const covertSizeTypeToNumber = (
  mapping: Partial<AliasToken>,
  sizeType: SizeType
) => {
  return sizeType === 'xxs'
    ? mapping.sizeXXS
    : sizeType === 'xs'
    ? mapping.sizeXS
    : sizeType === 'sm' || sizeType === 'small'
    ? mapping.sizeSM
    : sizeType === 'md' || sizeType === 'middle'
    ? mapping.sizeMD
    : sizeType === 'lg' || sizeType === 'large'
    ? mapping.sizeLG
    : sizeType === 'xl'
    ? mapping.sizeXL
    : sizeType === 'xxl'
    ? mapping.sizeXXL
    : (sizeType as number);
};

const detectAndConvertSizeTypes = (
  mapping: Partial<AliasToken>,
  sizeType: SizeType | [SizeType, SizeType]
): number | [number, number] => {
  if (typeof sizeType === 'number') {
    return sizeType;
  }

  if (typeof sizeType === 'string') {
    return covertSizeTypeToNumber(mapping, sizeType);
  }

  return [
    covertSizeTypeToNumber(mapping, sizeType?.[0]),
    covertSizeTypeToNumber(mapping, sizeType?.[1]),
  ];
};

export const useSize = (size: SizeType | [SizeType, SizeType]) => {
  const themeToken = useTheme();

  return detectAndConvertSizeTypes(themeToken, size);
};

export const getSize = (
  theme: Theme,
  size: SizeType | [SizeType, SizeType]
) => {
  const detectSize = detectAndConvertSizeTypes(theme.token, size);
  return typeof detectSize === 'number'
    ? detectSize
    : detectSize.map((s) => `${s}px`).join(' ');
};
