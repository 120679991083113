import type { I18nActiveNamespaces } from '.';

export type CommonConfig = {
  // Define namespaces in use in both the type and the config.
  i18nNamespaces: I18nActiveNamespaces<'common'>;
};
export const commonConfig: CommonConfig = {
  /** Namespaces that should be loaded for this page */
  i18nNamespaces: ['common'],
};

export const i18nNS = {
  common: 'common',
  gameResult: 'gameResult',
  landing: 'landing',
  validation: 'validation',
  business: 'business',
  error: 'error',
} as const;
