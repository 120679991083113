import type { FC } from 'react';
import React from 'react';
import type { TabsProps as AntdTabsProps } from 'antd';
import { Tabs as AntdTabs } from 'antd';
import styled from '@emotion/styled';
import { media } from '@candypig-monorepo/types';

const StyledTabs = styled(AntdTabs, {
  shouldForwardProp: (prop) => prop !== 'autoWidth',
})<TabsProps>(({ theme, autoWidth }) => ({
  // scoping style to this instance only
  ['&>.ant-tabs-nav']: {
    marginBottom: theme.token.sizeMD,
    [media.lg]: {
      marginBottom: theme.token.sizeXL,
    },
    ['.ant-tabs-nav-list']: {
      background: theme.components.Tabs.colorBgContainer,
      borderRadius: 40,
      height: 60,
      alignItems: 'stretch',
      padding: 4,
      gap: 8,
      [media.sm_max]: {
        height: 48,
        width: '100%',
      },
    },
    // tabs item
    ['.ant-tabs-tab']: {
      background: theme.components.Tabs.colorBgBase,
      borderRadius: 40,
      padding: `${theme.token.sizeXS}px ${theme.token.sizeXXL}px`,
      width: !autoWidth && 220,
      fontWeight: 900,
      justifyContent: 'center',
      [media.sm_max]: {
        padding: `${theme.token.sizeXS}px ${theme.token.sizeSM}px`,
        width: '50%',
        fontSize: '14px',
      },
    },
    // space between tabs item
    ['.ant-tabs-tab + .ant-tabs-tab']: {
      margin: 0,
    },
    ['.ant-tabs-tab > div']: {
      textAlign: 'center',
    },
    // active tabs
    ['.ant-tabs-ink-bar']: {
      background: 'none',
    },
    ['.ant-tabs-tab-btn, .ant-tabs-tab-btn:focus']: {
      color: theme.components.Tabs.colorTextBase,
    },
    ['.ant-tabs-tab.ant-tabs-tab-active']: {
      background: theme.components.Tabs.colorBgActive,
      ['.ant-tabs-tab-btn, .ant-tabs-tab-btn:focus']: {
        color: theme.token.colorWhite,
      },
    },
    ['.ant-tabs-tab.ant-tabs-tab-disabled']: {
      background: theme.components.Tabs.colorBgDisabled,
      ['.ant-tabs-tab-btn, .ant-tabs-tab-btn:focus']: {
        color: theme.token.colorTextDisabled,
      },
    },

    // remove tab bottom border
    ['&::before']: {
      borderBottom: 0,
    },
  },
}));

type TabsProps = AntdTabsProps & {
  autoWidth?: boolean;
  isCenter?: boolean;
};

const Tabs: FC<TabsProps> = ({ items, ...props }) => {
  return <StyledTabs items={items} {...props} centered={props.isCenter} />;
};

export default Tabs;
