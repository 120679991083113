import type { ReactNode } from 'react';
import React from 'react';
import NextLink from 'next/link';
import styled from '@emotion/styled';
import { Typography } from 'antd';
import type { LinkProps as AntdLinkProps } from 'antd/es/typography/Link';
import type { Property } from 'csstype';
import type { FontSizeType } from './useFontSize';
import { getFontSize } from './useFontSize';
import type { TypographyColor } from './useTypographyColor';
import { getTypographyColor } from './useTypographyColor';
import type { FontWeightType } from './useFontWeight';
import { getFontWeight } from './useFontWeight';

type LinkProps = Omit<AntdLinkProps, 'type'> & {
  type?: AntdLinkProps['type'];
  color?: TypographyColor;
  margin?: Property.Margin;
  size?: FontSizeType;
  children: ReactNode;
  weight?: FontWeightType;
};

const StyledLink = styled(Typography.Link, {
  shouldForwardProp: (propName) => !['margin'].includes(propName),
})<LinkProps>(({ weight, size, color, margin, theme }) => ({
  ['&.ant-typography']: {
    fontFamily: '"NunitoVariable", sans-serif',
    fontWeight: weight && getFontWeight(weight),
    fontSize: size && getFontSize(theme, size),
    color: color && getTypographyColor(theme, color),
    margin: typeof margin !== 'undefined' && margin,
  },
}));

const Link = ({ children, href, type, ...props }: LinkProps) => {
  return (
    <NextLink passHref legacyBehavior href={href ? href : ''}>
      <StyledLink {...(type && { type })} {...props}>
        {children}
      </StyledLink>
    </NextLink>
  );
};
export default Link;
