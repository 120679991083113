import type { ReactNode } from 'react';
import React from 'react';
import type { ParagraphProps as AntdParagraphProps } from 'antd/es/typography/Paragraph';
import type { Property } from 'csstype';

import type { FontSizeType } from './useFontSize';
import { getFontSize } from './useFontSize';
import type { TypographyColor } from './useTypographyColor';
import { getTypographyColor } from './useTypographyColor';
import type { SizeType } from '../layout/useSize';
import styled from '@emotion/styled';
import type { FontWeightType } from './useFontWeight';
import { getFontWeight } from './useFontWeight';
import { media } from '@candypig-monorepo/types';

type ParagraphProps = AntdParagraphProps & {
  children: ReactNode;
  color?: TypographyColor;
  size?: FontSizeType;
  align?: Property.TextAlign;
  margin?: Property.Margin;
  marginBottom?: SizeType;
  lineHeight?: string;
  weight?: FontWeightType;
  sm?: {
    size?: FontSizeType;
    align?: Property.TextAlign;
    lineHeight?: string;
  };
  md?: {
    size?: FontSizeType;
    align?: Property.TextAlign;
    lineHeight?: string;
  };
  lg?: {
    size?: FontSizeType;
    align?: Property.TextAlign;
    lineHeight?: string;
  };
};

const StyledParagraph = styled('p', {
  shouldForwardProp: (propName) => !['margin'].includes(propName),
})<ParagraphProps>(
  ({
    weight,
    size,
    lineHeight,
    color = 'default',
    margin,
    marginBottom,
    align,
    theme,
    sm,
    md,
    lg,
  }) => ({
    fontWeight: weight && getFontWeight(weight),
    fontVariationSettings: weight && `"wght" ${getFontWeight(weight)}`,
    fontSize: getFontSize(theme, size || 'md'),
    color: color && getTypographyColor(theme, color),
    textAlign: align,
    lineHeight: lineHeight,
    wordWrap: 'break-word',
    margin: typeof margin !== 'undefined' && margin,
    marginBottom: typeof marginBottom !== 'undefined' && marginBottom,
    [media.sm]: {
      fontSize: getFontSize(theme, sm?.size || size || 'md'),
      textAlign: sm?.align,
    },
    [media.md]: {
      fontSize: getFontSize(theme, md?.size || sm?.size || size || 'md'),
      textAlign: md?.align,
    },
    [media.lg]: {
      fontSize: getFontSize(
        theme,
        lg?.size || md?.size || sm?.size || size || 'md'
      ),
      textAlign: lg?.align,
    },
  })
);

// NOTE: Typography.Paragraph is not rendering content as p BUT DIV
// We will use a normal p combined with antd props for this component
const Paragraph = ({ children, ...props }: ParagraphProps) => {
  return <StyledParagraph {...props}>{children}</StyledParagraph>;
};

export default Paragraph;
