import { useTheme } from '../../../libs/hooks/useTheme';
import type { Theme } from '@emotion/react';

export type FontSizeType =
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | 'xxl'
  | 'xxxl'
  | number;

export const useFontSize = (size: FontSizeType = 'md') => {
  const { fontSize, fontSizeLG, fontSizeXL, fontSizeSM } = useTheme();
  return size === 'xs'
    ? 12
    : size === 'sm'
    ? fontSizeSM
    : size === 'md'
    ? fontSize
    : size === 'lg'
    ? fontSizeLG
    : size === 'xl'
    ? fontSizeXL
    : size;
};

export const getFontSize = (theme: Theme, size: FontSizeType) => {
  return size === 'xs'
    ? 12
    : size === 'sm'
    ? theme.token.fontSizeSM
    : size === 'md'
    ? theme.token.fontSize
    : size === 'lg'
    ? theme.token.fontSizeLG
    : size === 'xl'
    ? theme.token.fontSizeXL
    : size === 'xxl'
    ? theme.token.fontSizeXXL
    : size === 'xxxl'
    ? theme.token.fontSizeXXXL
    : size;
};
